//本地
// let env = 'dev'
//生产
let env = 'dev'

let baseUrl = '';

if (env=='dev') {
  baseUrl = 'http://localhost:8081'
} else {
  baseUrl = 'http://datatalks.ai:5000'
}

export default  baseUrl