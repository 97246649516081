<!--  -->
<template>
  <div class="assets-box">


    <div>

      <h3 style="text-align: center; font-size: 22px; padding-top: 22px; padding-bottom: 11px;">月度数据预测说明</h3>

      <div>
        <el-steps :active="4" align-center>
          <el-step title="业务维度" description="确定业务对象,选择业务维度"></el-step>
          <el-step title="模型配置" description="确定模型的类型,以及模型对应的参数"></el-step>
          <el-step title="模型预测" description="将模型的输入,提交给模型进行调用和运算"></el-step>
          <el-step title="结果评估" description="基于模型返回的结果,进行验证和评估"></el-step>
        </el-steps>
      </div>
    </div>




    <div class="">
      <h3 class="business-map">业务维度</h3>
      <div class="">
        <ul class="business-tab">
          <li class="tab active" data-index="0" @click="onTab($event, 0)">
            <p class="">总量数据</p>
            <p class="">总体的销量,销额等数据指标</p>
          </li>
          <li class="tab" data-index="1" @click="onTab($event, 1)">
            <p class="">品类数据</p>
            <p class="">烟草行业品类的标准,汇总的数据指标</p>
          </li>
          <li class="tab" index="2" @click="onTab($event, 2)">
            <p class="">单品数据</p>
            <p class="">烟草单品品规的级别数据指标</p>
          </li>
          <li class="tab" index="3" @click="onTab($event, 3)">
            <p class="">单品分类数据</p>
            <p class="">这块还没有看懂~~~~~~</p>
          </li>
        </ul>
      </div>
      <div class="data-field">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="数据可视化" name="first">
            <div class="data-field-list clearfix">
              <div class="fl field-list-l">
                <h3 class="dimension-business">数据展现-表</h3>

                <el-table :data="tableData" height="250" border style="width: 100%; ">
                  <el-table-column prop="month" label="月份">
                  </el-table-column>
                  <el-table-column prop="name" label="品牌名称">
                  </el-table-column>
                  <el-table-column prop="cost" label="成本">
                  </el-table-column>
                  <el-table-column prop="sales_no" label="销售数量">
                  </el-table-column>
                </el-table>

              </div>
              <div class="fr field-list-r">
                <h3 class="dimension-business">数据展现-图</h3>
                <div style="width:100%;height:250px" id="echarts1"></div>
              </div>
            </div>



          </el-tab-pane>
          <el-tab-pane label="模型配置" name="second">
            <div class="">


              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item   title="加权移动平均模型" name="1">
                  <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
                  <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>

                  <div>
                    <el-descriptions title="参数列表" :column="1" border>
                      <template slot="extra">
                        <el-button type="primary" size="small">更新</el-button>
                      </template>
                      <el-descriptions-item label="模型权重：" label-class-name="my-label" content-class-name="my-content">
                        <el-row type="flex">
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="请输入权重数值..."></el-input> </el-col>
                        </el-row>

                      </el-descriptions-item>
                      <el-descriptions-item label="基数区间：">
                        <div class="block">
                          <el-date-picker v-model="value2" type="month" placeholder="选择月">
                          </el-date-picker>
                        </div>
                      </el-descriptions-item>
                      <el-descriptions-item label="增量区间：">

                        <el-row type="flex">
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px;"> From: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                          <div style="height: 100%; padding-top: 8px;padding-right: 6px; padding-left: 8px;"> To: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                        </el-row>

                      </el-descriptions-item>
                      <el-descriptions-item label="权重系数：">
                        <el-row type="flex">
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="0.4"></el-input> </el-col>
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="0.3"></el-input> </el-col>
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="0.2"></el-input> </el-col>
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="0.1"></el-input> </el-col>
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px; padding-left: 3px;">
                            （各权重系数之和必须等于1）
                          </div>

                        </el-row>
                      </el-descriptions-item>
                      <el-descriptions-item label="计算公式：">
                        <math-jax latex="   Q_{预测区间} = Q_{基数区间}   +   \Delta Q_{增量区间} "></math-jax>
                      </el-descriptions-item>
                    </el-descriptions>

                  </div>
                </el-collapse-item>
                <el-collapse-item title="灰色模型(GM)" name="2">
                  <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
                  <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>



                  <div>
                    <el-descriptions title="参数列表" :column="1" border>
                      <template slot="extra">
                        <el-button type="primary" size="small">更新</el-button>
                      </template>
                      <el-descriptions-item label="模型权重：" label-class-name="my-label" content-class-name="my-content">
                        <el-row type="flex">
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="请输入权重数值..."></el-input> </el-col>
                        </el-row>

                      </el-descriptions-item>
                      <el-descriptions-item label="抽样区间：">

                        <el-row type="flex">
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px;"> From: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                          <div style="height: 100%; padding-top: 8px;padding-right: 6px; padding-left: 8px;"> To: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px; padding-left: 3px;">
                            （抽样区间至少为4期）
                          </div>
                        </el-row>
                      </el-descriptions-item> 
                    </el-descriptions>

                  </div>

                </el-collapse-item>
                <el-collapse-item title="多元线性回归模型" name="3">
                  <div>简化流程：设计简洁直观的操作流程；</div>
                  <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
                  <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>





                  <div>
                    <el-descriptions title="参数列表" :column="1" border>
                      <template slot="extra">
                        <el-button type="primary" size="small">更新</el-button>
                      </template>
                      <el-descriptions-item label="模型权重：" label-class-name="my-label" content-class-name="my-content">
                        <el-row type="flex">
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="请输入权重数值..."></el-input> </el-col>
                        </el-row>

                      </el-descriptions-item>
                      <el-descriptions-item label="基数区间：">
                        <div class="block">
                          <el-date-picker v-model="value2" type="month" placeholder="选择月">
                          </el-date-picker>
                        </div>
                      </el-descriptions-item>
                      <el-descriptions-item label="增量区间：">

                        <el-row type="flex">
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px;"> From: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                          <div style="height: 100%; padding-top: 8px;padding-right: 6px; padding-left: 8px;"> To: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                        </el-row>

                      </el-descriptions-item>
                      <el-descriptions-item label="计算公式：">
                        <el-row type="flex">
                        <math-jax style="height: 100%; padding-top: 8px;padding-right: 6px; padding-left: 8px;"
                         latex="  y = 	\beta + 	\beta_1x_1 + \beta_2x_2  + \beta_3x_3  + \beta_4x_4 + \beta_5x_5 + \beta_6x_6"></math-jax>
                        <div style="height: 100%; padding-top: 8px;padding-right: 3px; padding-left: 3px;">
                            （抽样区间至少为4期）
                          </div>
                        </el-row>
                      </el-descriptions-item>
                    </el-descriptions>

                  </div>





                </el-collapse-item>
                <el-collapse-item title="时间序列模型(ARIMA)" name="4">
                  <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
                  <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>



                  <div>
                    <el-descriptions title="参数列表" :column="1" border>
                      <template slot="extra">
                        <el-button type="primary" size="small">更新</el-button>
                      </template>
                      <el-descriptions-item label="模型权重：" label-class-name="my-label" content-class-name="my-content">
                        <el-row type="flex">
                          <el-col :span="4" style="padding-right: 3px;"><el-input v-model="input"
                              placeholder="请输入权重数值..."></el-input> </el-col>
                        </el-row>

                      </el-descriptions-item>
                      <el-descriptions-item label="抽样区间：">

                        <el-row type="flex">
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px;"> From: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                          <div style="height: 100%; padding-top: 8px;padding-right: 6px; padding-left: 8px;"> To: </div>
                          <div class="">
                            <el-date-picker v-model="value2" type="month" placeholder="选择月">
                            </el-date-picker>
                          </div>
                          <div style="height: 100%; padding-top: 8px;padding-right: 3px; padding-left: 3px;">
                            （抽样区间至少为5期）
                          </div>
                        </el-row>
                      </el-descriptions-item> 
                    </el-descriptions>

                  </div>

                </el-collapse-item>
              </el-collapse>


            </div>
          </el-tab-pane>
          <el-tab-pane label="模型预测" name="third">
            <div class="data-field-list clearfix">
              <div class="fl field-list-l">
                <h3 class="dimension-business">技术维度</h3>
                <div class="list">
                  <ul class="clrafix">
                    <li><span>监控技术维度</span><span>(1)</span></li>
                    <li><span>监控技术维度</span><span>(2)</span></li>
                    <li><span>监控技术维度</span><span>(3)</span></li>
                    <li><span>监控技术维度</span><span>(4)</span></li>
                    <li><span>监控技术维度</span><span>(5)</span></li>
                    <li><span>监控技术维度</span><span>(6)</span></li>
                    <li><span>监控技术维度</span><span>(7)</span></li>
                    <li><span>监控技术维度</span><span>(8)</span></li>
                  </ul>
                </div>
              </div>
              <div class="fr field-list-r">
                <h3 class="dimension-business">业务维度</h3>
                <div class="list">
                  <ul class="clearfix">
                    <li><span>监控业务维度</span><span>(1)</span></li>
                    <li><span>监控业务维度</span><span>(2)</span></li>
                    <li><span>监控业务维度</span><span>(3)</span></li>
                    <li><span>监控业务维度</span><span>(4)</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="结果评估" name="fourth">
            <div class="data-field-list clearfix">
              <div class="fl field-list-l">
                <h3 class="dimension-business">技术维度</h3>
                <div class="list">
                  <ul class="clrafix">
                    <li><span>车辆技术维度</span><span>(1)</span></li>
                    <li><span>车辆技术维度</span><span>(2)</span></li>
                    <li><span>车辆技术维度</span><span>(3)</span></li>
                    <li><span>车辆技术维度</span><span>(4)</span></li>
                    <li><span>车辆技术维度</span><span>(5)</span></li>
                    <li><span>车辆技术维度</span><span>(6)</span></li>
                    <li><span>车辆技术维度</span><span>(7)</span></li>
                    <li><span>车辆技术维度</span><span>(8)</span></li>

                  </ul>
                </div>
              </div>
              <div class="fr field-list-r">
                <h3 class="dimension-business">业务维度</h3>
                <div class="list">
                  <ul class="clearfix">
                    <li><span>车辆业务维度</span><span>(1)</span></li>
                    <li><span>车辆业务维度</span><span>(2)</span></li>
                    <li><span>车辆业务维度</span><span>(3)</span></li>
                    <li><span>车辆业务维度</span><span>(4)</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>



    <br>

    <!-- 一个图表的样式测试 -->
    <!-- <div style="width:100%;height:550px">
      <div style="width:100%;height:550px" id="echarts2"></div>
    </div> -->


  </div>
</template>
<script>

import * as echarts from 'echarts'
import baseUrl from '../../../util/config'
//  document.getElementById('XXX') 函数式的使用场景
import $ from 'jquery'
export default {
  data() {
    return {
      tableData: [],
      // input: '',
      activeName: 'first',
      activeName2: "first2",
      businessTab: [{
      }],
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（访问DOM元素）,函数调用
  mounted() {
    this.getTableData(),
      this.ecahrts1(),
      this.ecahrts2()
  },
  //计算属性
  computed: {},
  //函数
  methods: {

    //ecahrts2
    ecahrts2() {
      let echarts2 = document.getElementById('echarts2')
      let myecharts2 = echarts.init(echarts2)
      let option
      option = {
        title: {
          text: '任务类别分布',
          textStyle: {
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'item'
        },

        legend: {
          orient: 'vertical',
          left: '70%', //图例距离左的距离
          y: 'center' //图例上下居中
        },

        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '合理' },
              { value: 735, name: '一致' },
              { value: 580, name: '合规' },
              { value: 484, name: '冗余' },
              { value: 300, name: '完整' }
            ]
          }
        ]
      }
      myecharts2.setOption(option, true)
    },

    //数据指标
    getecharts1Data() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/gray_model/model_data4chart',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          this.echarts1month_list = res.data.resultData.month_list
          this.echarts1cost_list = res.data.resultData.cost_list
          this.echarts1sales_no_list = res.data.resultData.sales_no_list
        },
        err => { }
      )
    },


    //数据指标
    getTableData() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/gray_model/model_data4table',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          // console.log(res.data)
          this.tableData = res.data.resultData
        },
        err => { }
      )
    },


    ecahrts1() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/gray_model/model_data4chart',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          console.log(res.data.resultData)

          let echarts1 = document.getElementById('echarts1')
          let myecharts1 = echarts.init(echarts1)
          let option = {
            title: {
              text: '数据指标趋势图'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {},
            toolbox: {
              show: true,
              feature: {
                dataZoom: {
                  yAxisIndex: 'none'
                },
                dataView: { readOnly: false },
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: res.data.resultData.month_list
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: '{value}'
              }
            },
            series: [
              {
                name: '成本金额(元)',
                type: 'line',
                data: res.data.resultData.cost_list,
                markPoint: {
                  data: [
                    { type: 'max', name: 'Max' },
                    { type: 'min', name: 'Min' }
                  ]
                },
                markLine: {
                  data: [{ type: 'average', name: 'Avg' }]
                }
              },
              {
                name: '销售数量(万箱)',
                type: 'line',
                data: res.data.resultData.sales_no_list,
                markPoint: {
                  data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
                },
                markLine: {
                  data: [
                    { type: 'average', name: 'Avg' },
                    [
                      {
                        symbol: 'none',
                        x: '90%',
                        yAxis: 'max'
                      },
                      {
                        symbol: 'circle',
                        label: {
                          position: 'start',
                          formatter: 'Max'
                        },
                        type: 'max',
                        name: '最高点'
                      }
                    ]
                  ]
                }
              }
            ]
          }
          myecharts1.setOption(option, true)
        },
        err => { }
      )
    },




    handleClick(tab, event) {
      console.log(tab, event)
    },
    handleClick2(tab, event) {
      console.log(tab, event)
    },

    //切换tab
    onTab(ev, index) {
      let dom = document.getElementsByClassName("tab");
      $('.tab').removeClass("active")
      for (let i = 0; i < dom.length; i++) {
        $('.tab').eq(index).addClass("active")
      }
    }
  },
  //监控
  watch: {}
}
</script>
<style scoped>


.el-collapse-item >>> .el-collapse-item__header {
  /* background: #262720; */
  color: #4091f7;
  font-size: 13px;
  /* height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-bottom: 1px solid #4b4c44;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end; */
}
/* 
.el-collapse-item >>> .el-collapse-item__wrap {
  background: #262720;
  border-bottom: 1px solid #4b4c44;
  color: #ffffff;
}  */


.my-label {
  background: #E1F3D8;
  text-align: 'center';
}

.my-content {
  background: #FDE2E2;
  text-align: 'center';

}


.assets-box {
  background-color: #f8f9fa;
}

.search-box {
  background-color: #f0f2f5;
}

.search-box .search {
  width: 500px;
  margin: 0 auto;
}

.search-box .search .search-input {
  width: 400px;
}

.search-box .search .search-btn {
  margin-left: 10px;
  padding: 6px 15px;
}

.search-box .search-title {
  width: 100%;
  text-align: center;
}

.search-title li {
  display: inline-block;
  margin: 20px;
  font-size: 14px;
  color: #a6a4a5;
}

.assets-box .business-map {
  font-size: 18px;
  color: #111217;
  text-align: left;
  padding: 20px;
  font-weight: bold;
}

.assets-box .business-tab {
  display: flex;
  padding: 0 20px;
  background-color: #f8f9fa;
}

.assets-box .business-tab li {
  flex: 1;
  width: 25%;
  height: 117px;
  box-sizing: border-box;
  background-color: #fff;
  margin-right: 20px;
}

.assets-box .business-tab li:last-child {
  margin-right: 0;
}

.assets-box .business-tab li p:first-child {
  padding: 20px 0 10px 0;
  font-size: 17px;
  color: #000;
  font-weight: bold;
}

.assets-box .business-tab li p:last-child {
  padding: 0 0 40px 0;
  font-size: 13px;
  color: #8e817f;
}

.assets-box .business-tab .active {
  border-top: 3px solid #4091f7;
  box-sizing: border-box;
}

.assets-box .data-field {
  margin: 0 20px;
  padding: 0 20px;
  background-color: #fff;
}

.assets-box .data-field .dimension-business {
  font-size: 13px;
  color: #000;
  font-weight: bold;
}

.assets-box .data-field-list {
  text-align: left;
}

.assets-box .data-field .field-list-l {
  width: 35%;
  border-right: 2px solid #e9e9e9;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.assets-box .data-field .field-list-r {
  width: 65%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-bottom: 10px;
}

.assets-box .data-field-list .list li {
  float: left;
  padding-right: 20px;
  padding-top: 10px;
  color: #2e211e;
  font-weight: bold;
}

.assets-box .mine-box {
  padding-top: 20px;
}

.assets-box .mine-box .mine-l {
  width: 70%;
}

.assets-box .mine-box .mine-r {
  width: 30%;
}

.assets-box .mine-box .mine-r .popular {
  font-size: 15px;
  color: #111217;
  font-weight: bold;
  text-align: left;
  padding: 15px 10px 15px 10px;
}

.assets-box .mine {
  margin: 20px;
}

.assets-box .mine .mine-l .mine-list {
  float: left;
  width: 48%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  margin-left: 2%;
  margin-bottom: 10px;
}

.assets-box .mine .mine-r .mine-list {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  margin-left: 2%;
  margin-bottom: 10px;
}

.assets-box .mine-list .title {
  color: #000;
  font-weight: bold;
}

.assets-box .mine-list .title .el-icon-receiving {
  color: #90b2f1;
}

.assets-box .mine-list .title .el-icon-star-on {
  color: #d1d1d1;
}

.assets-box .mine-list .title span {
  margin: 0 4px;
}
</style>
